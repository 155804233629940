<template>
    <tr>
        <slot></slot>
    </tr>
</template>

<style scoped>
tr {
    cursor: pointer;
    transition: all 200ms ease-out;
}

tr:nth-child(even) {
    background-color: #F2F2F7;
}

tr:hover {
    background-color: var(--color-light-blue);
}
</style>