import { ref } from 'vue';
import { defineStore } from 'pinia';
import SKUService from '@/services/sku.service';

export const useSKUStore = defineStore('sku', () => {
	const service = new SKUService();
	const list = ref([]);

	const getSKUs = async () => {
		list.value = [];
		list.value = (await service.GetSKUs()) ?? [];
		list.value = list.value.map((item) => {
			return item;
		});
	};

	const createSKU = async (sku) => {
		await service.CreateSKU(sku);
	};

	const updateNote = async (sku) => {
		await service.UpdateSKUNote(sku);
	};

	return {
		getSKUs,
		createSKU,
		updateNote,
		list,
	};
});
