import './assets/main.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import {
	onFulfilledInterceptor,
	onRejectedInterceptor,
} from '@/services/request.interceptor';

const app = createApp(App);

app.use(createPinia());
app.use(router);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.response.use(onFulfilledInterceptor, onRejectedInterceptor);

app.mount('#app');
