import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', () => {
	const getSavedAuth = () => {
		const auth = JSON.parse(localStorage.getItem('auth'));

		if (auth && auth.token) {
			axios.defaults.headers = { Authorization: auth?.token };
		}

		return auth;
	};

	const auth = ref(getSavedAuth());

	const getToken = () => {
		return auth.value.token;
	};

	const logIn = async (token) => {
		const response = await axios.post('/auth/login', { token: token });

		auth.value = {
			role: response.data.role,
			token: response.data.token,
		};

		localStorage.setItem('auth', JSON.stringify(auth.value));

		axios.defaults.headers = { Authorization: response.data.token };
	};

	const logOut = () => {
		auth.value = undefined;
		localStorage.clear('auth');
		axios.defaults.headers = {};
	};

	const isAuthenticated = () => {
		return auth.value && auth.value.token != '' && auth.value.role;
	};

	const checkPermission = (required) => {
		return (
			required?.includes(auth?.value?.role) ||
			process.env.VUE_APP_IS_STAGING === 'true'
		);
	};

	return { auth, getToken, logIn, logOut, isAuthenticated, checkPermission };
});
