export const LoadingState = {
	Idle: 0,
	Loading: 1,
	Done: 2,
	Error: 3,
};

export const Ecosystem = {
	iTranslate: 'itranslate',
	Grammatica: 'grammatica',
	SpeakAndTranslate: 'speak&translate',
};

export const AppBundleIDs = {
	iOSiTranslate: 'com.outerspaceapps.itranslate',
	AndroidiTranslate: 'at.nk.tools.iTranslate',
	iOSGrammatica: 'com.itranslate.grammatica.ios',
	AndroidGrammatica: 'com.itranslate.grammatica.android',
	SpeakAndTranslateIOs: 'com.appicfun.translatorfree',
};

export const SubscriptionProvider = {
	apple: 'Apple',
	google: 'Google',
	stripe: 'Stripe',
};

export const EditState = {
	Idle: 0,
	Editing: 1,
};