<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { LoadingState } from '@/services/enums.js';
import { ShowError } from '@/services/util';
import { useSKUStore } from '@/stores/sku.store';

const store = useSKUStore();
const props = defineProps(['sku']);
const emit = defineEmits(['close']);
const sku = ref(props.sku);
const updateState = ref(LoadingState.Idle);

const update = async () => {
	try {
		updateState.value = LoadingState.Loading;
		await store.updateNote(sku.value);
		emit('close');
	} catch (e) {
		ShowError(e);
	} finally {
		updateState.value = LoadingState.Done;
	}
};
</script>

<template>
    <div class="modal-backdrop" @mousedown="emit('close')">
        <div class="modal" @click.stop @mousedown.stop>
            <div class="modal-header">
                <AppButton class="secondary small" @click="emit('close')">Close</AppButton>
                <h2>Note</h2>
            </div>
			<div>
				<form action="submit">
					<label>
						<p>SKU Note:</p>
						<textarea class="textarea" v-model="sku.note" type="text" placeholder="Note" />
					</label>
					<div class="align-center-row">
						<AppButton @click="update()" :disabled="updateState === LoadingState.Loading" :loading="updateState === LoadingState.Loading" type="submit">Update SKU Note</AppButton>
					</div>
				</form>
			</div>
        </div>
    </div>
</template>

<style scoped>
form {
	margin: 2em;
	min-width: 800px;
}

label {
	width: 100%;
	font-size: 1.1em;
}

label p {
	margin-left: 2em;
	margin-top: 0.5em;
}

.textarea {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	min-height: 150px;
	min-width: 220px;
	width: 100%;
	background-color: white;
	border-color: var(--color-secondary-fill);
	border-width: 2px;
	border-style: solid;
	margin: 0.5em;
	padding: 1em;
}

.area {
	min-height: 250px;
}
</style>