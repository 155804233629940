import axios from 'axios';

export default class SKUService {
	async GetSKUs() {
		let res = await axios.get('/admin/skus');
		res.data = res.data.map((item) => {
			if (
				item.product_id.includes('itranslate') ||
				item.bundle_id.includes('itranslate') ||
				item.title.toLowerCase().includes('itranslate')
			) {
				item.app = 'itranslate';
			} else if (
				item.product_id.includes('grammatica') ||
				item.bundle_id.includes('grammatica') ||
				item.title.toLowerCase().includes('grammatica')
			) {
				item.app = 'grammatica';
			} else if (
				item.product_id.includes('speak_translate') ||
				item.bundle_id.includes('com.appicfun.translatorfree') ||
				item.title.toLowerCase().includes('speak_translate')
			) {
				item.app = 'speak&translate';
			}
			else {
				item.app = 'unknown';
			}
			return item;
		});
		return res.data;
	}

	async CreateSKU(sku) {
		sku.intro = `${sku.intro}`;
		await axios.post('/admin/skus', sku);
	}

	async UpdateSKUNote(sku) {
		await axios.put(`/admin/skus/${sku.id}/note`, sku);
	}
}
