<script setup>
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import TableView from '@/components/table/TableView.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableData from '@/components/table/TableData.vue';
import TableRow from '@/components/table/TableRow.vue';
import { useAccountStore } from '@/stores/account.store.js';
import { LoadingState, Ecosystem } from '@/services/enums.js';
import { ShowError } from '@/services/util.js';
import { useRouter } from 'vue-router';

const accountStore = useAccountStore();
const router = useRouter();

let ecosystem = ref('');
let email = ref('');
let userID = ref('');
let state = ref(LoadingState.Idle);

const search = async () => {
	try {
		state.value = LoadingState.Loading;
		await accountStore.getAccounts({ ecosystem: ecosystem.value, email: email.value, uuid: userID.value });
		accountStore.list.forEach(element => {
			if (element.subscription_error) {
				ShowError(`Failed to get subscription status for user: ${element.id} ${element.email} ${element.ecosystem}`)
			}
		});
		state.value = LoadingState.Done;
	} catch (error) {
		state.value = LoadingState.Error;
		ShowError(error);
	}
}

const isValid = () => {
	return email.value != "" || userID.value != "";
}

const openDetails = (account) => {
	accountStore.setAccountDetails(account);
	router.push({ name: 'UserDetails', params: { id: account.id } });
}
</script>
<template>
	<div class="page-container">
		<h1>Advanced Search</h1>
		<div class="search-container">
		<div class="row">
				<div class="chip" @click="ecosystem = ''" :class="{active: ecosystem === ''}">
					<p>All</p>
				</div>
				<div class="chip" @click="ecosystem = 'itranslate'" :class="{active: ecosystem === 'itranslate'}">
					<p>iTranslate</p>
				</div>
				<div class="chip" @click="ecosystem = 'grammatica'" :class="{active: ecosystem === 'grammatica'}">
					<p>Grammatica</p>
				</div>
			</div>
			<form @submit.prevent="search">
				<div class="row">
					<div class="input-field">
					<input v-model="userID" type="text" placeholder="Search by User ID" />
					</div>
					<div class="input-field">
						<input v-model="email" type="text" placeholder="Search by Email" />
					</div>
					<AppButton type="submit" @click="search()" :disabled="!isValid()" :loading="state === LoadingState.Loading">
						Search
					</AppButton>
				</div>
			</form>
		</div>
		<TableView class="table" :loading="state === LoadingState.Loading" v-if="state === LoadingState.Done || accountStore.list?.length !== 0">
            <template #header>
				<TableHeader class="align-to-center">Active Subscription</TableHeader>
                <TableHeader>User ID</TableHeader>
                <TableHeader>Email</TableHeader>
				<TableHeader>Ecosystem</TableHeader>
				<TableHeader class="align-to-center">Verified</TableHeader>
            </template>
            <template #item>
                <TableRow 
					v-for="account in accountStore.list" 
					:key="account?.id"
					@click="openDetails(account)"
				>
					<TableData class="align-to-center"> 
						<div class="dot" :class="{'inactive': !account.sub?.data?.active}"></div>
					</TableData>
					<TableData>{{ account.id }}</TableData>
					<TableData class="bold">{{ account.email }}</TableData>
					<TableData>{{ account.ecosystem == Ecosystem.iTranslate ? 'iTranslate' : 'Grammatica' }}</TableData>
					<TableData class="align-to-center"> 
						<div class="dot" :class="{'inactive': !account.activated}"></div>
					</TableData>
                </TableRow>
            </template>
        </TableView>
		<div v-if="state === LoadingState.Done && accountStore.list?.length === 0">
			<p class="no-results-text">No results found</p>
		</div>
	</div>
</template>

<style scoped>
.page-container {
	width: 100vw;
	height: 100wh;
}
.search-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	min-height: 250px;
	width: 100%;
	margin: 0 auto;
	background: var(--color-light-blue);
	padding: 0 2em;
}

.search-container .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.chip {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--color-secondary-fill);
	border-radius: 24px;
	height: 34px;
	padding: 0 0.4em;
	margin: auto 0.2em;
	color: var(--color-secondary-fill);
	cursor: pointer;
}

.chip p {
	font-size: 1em;
	font-weight: 500;
	padding: 0 0.5em;
}

.chip.active {
	color: var(--color-dark);
	border-color: var(--color-dark);
	background: var(--color-blue-transparent)
}

.dot {
	height: 10px;
	width: 10px;
	background-color: var(--color-blue);
	border-radius: 50%;
	display: inline-block;
}

.dot.inactive {
	background-color: red;
}

.align-to-center {
	text-align: center;
}

.no-results-text {
	text-align: center;
	color: var(--color-dark);
	margin: 5.5em auto;
	font-size: 1.5em;
}
</style>