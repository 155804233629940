<template>
    <th class="table">
        <slot></slot>
    </th>
</template>

<style scoped>
th {
    color: var(--color-dark);
    font-weight: 500;
    font-size: 1.2em;
    border-collapse: collapse;
    padding: 5px 10px;
    min-height: 50px;
    border-bottom: solid 1px #919699;
    padding: 1em 0;
}

.small {
    font-size: 0.9em;
    min-height: 35px;
}
</style>