<script setup>
import { onMounted, ref } from "vue";
import { useAccountStore } from "@/stores/account.store.js";
import { LoadingState, EditState } from "@/services/enums.js";
import { FormatDate, ShowError } from "@/services/util.js";
import { useRoute, useRouter } from "vue-router";
import AppButton from "@/components/AppButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TableView from '@/components/table/TableView.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableData from '@/components/table/TableData.vue';
import TableRow from '@/components/table/TableRow.vue';
import { useAuthStore } from "@/stores/auth";
import { ActivateAccountPermission, DeleteAccountPermission, SetPremiumAccountPermission } from "@/services/permissions";

const accountStore = useAccountStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

let accountLoadingState = ref(LoadingState.Loading);
let subscriptionUpdateState = ref(LoadingState.Idle);
let deleteAccountState = ref(LoadingState.Idle);
let verifyAccountState = ref(LoadingState.Idle);
let getDevicesState = ref(LoadingState.Loading);
let accountID = ref(route.params.id);
let accountEmail = ref('');
let emailEditState = ref(EditState.Idle);
let emailUpdateState = ref(LoadingState.Idle);

onMounted(() => {
	loadAccount();
	loadDevices();
});

const loadAccount = async () => {
		try {
			if (accountStore.accountDetails === null || accountStore.accountDetails.id !== accountID.value) {
				await accountStore.getAccountById(accountID.value);
			}
			accountEmail.value = accountStore.accountDetails.email;
			accountLoadingState.value = LoadingState.Done;
		} catch (error) {
			accountLoadingState.value = LoadingState.Error;
			ShowError(error);
		}
}

const loadDevices = async () => {
	try {
		getDevicesState.value = LoadingState.Loading;
		await accountStore.getDevices(accountID.value);
		getDevicesState.value = LoadingState.Done;
	} catch (e) {
		ShowError(e);
		getDevicesState.value = LoadingState.Error;
	}
}

const verifyAccount = async () => {
	try {
		verifyAccountState.value = LoadingState.Loading;
		await accountStore.verifyAccount(accountID.value);
	} catch (e) {
		ShowError(e);
	} finally {
		setTimeout(() => {
			verifyAccountState.value = LoadingState.Idle;
		}, 2000);
	}
}

const changePremiumStatus = async () => {
	try {
		subscriptionUpdateState.value = LoadingState.Loading;
		await accountStore.changePremiumStatus(accountID.value);
	} catch (e) {
		ShowError(e);
	} finally {
		subscriptionUpdateState.value = LoadingState.Idle;
	}
};

const deleteAccount = async () => {
	if (!window.confirm('Are you sure you want to delete this account?')) return;
	try {
		deleteAccountState = LoadingState.Idle;
		await accountStore.deleteAccount(accountID.value);
		accountStore.clearList();
		router.push({ name: 'UserSearch'});
	} catch (e) {
		ShowError(e);
		deleteAccountState = LoadingState.Idle;
	}
};

const updateEmail = async () => {
	window.confirm(`Are you sure you want to change the email to ${accountEmail.value}?`);
	try {
		emailUpdateState.value = LoadingState.Loading;
		await accountStore.updateEmail(accountID.value, accountEmail.value);
		emailEditState.value = EditState.Idle;
		emailUpdateState.value = LoadingState.Idle;
	} catch (e) {
		ShowError(e);
		emailUpdateState.value = LoadingState.Idle;
	}
};
</script>

<template>
	<div class="page-container">
		<div class="page-header">
			<div class="back-button" @click="router.push({ name: 'UserSearch'})">
				<img src="@/assets/icons/arrow_back.svg" alt="">
				<p>{{ accountID }}</p>
			</div>
			<div class="back-button" v-if="accountStore.accountDetails !== null">
				<AppButton 
					class="secondary small" 
					:loading="subscriptionUpdateState === LoadingState.Loading" 
					@click="changePremiumStatus()"
					v-if="authStore.checkPermission(SetPremiumAccountPermission)"
				>
					{{accountStore.accountDetails.premium_subscription ? "Remove Premium" : 'Add Premium'}}
				</AppButton>
				<AppButton 
					@click="verifyAccount()" 
					class="secondary small" 
					:loading="verifyAccountState === LoadingState.Loading" 
					v-if="!accountStore.accountDetails.activated && authStore.checkPermission(ActivateAccountPermission)"
				>Verify account</AppButton>
				<AppButton 
					@click="deleteAccount()" 
					class="small" 
					:loading="deleteAccountState === LoadingState.Loading"
					v-if="authStore.checkPermission(DeleteAccountPermission)"
				>Delete user</AppButton>
			</div>
		</div>
		<div class="grid" v-if="accountLoadingState === LoadingState.Done">
			<div class="info-container">
				<h3>Account details</h3>
				<div class="row">
					<p class="label">
						Ecosystem
					</p>
					<p class="value">
						{{ accountStore.accountDetails.ecosystem }}
					</p>
				</div>
				<div class="row">
					<p class="label">
						Platforms used
					</p>
					<div class="platform-chip small" v-if="accountStore.devices.ios">iOS</div>
					<div class="platform-chip small" v-if="accountStore.devices.android">Android</div>
					<div class="platform-chip small" v-if="accountStore.devices.web">Web</div>
				</div>
				<div class="row">
					<p class="label">
						Subscription status
					</p>
					<p class="value">
						{{ 
							accountStore.accountDetails.sub && accountStore.accountDetails.sub?.data?.active 
							? "Active" : accountStore.accountDetails.sub?.data && !accountStore.accountDetails.sub?.data?.active 
							? "Expired" : "None" 
						}} 
						{{ 
							accountStore.accountDetails.premium_subscription ? " - Premium" : ""
						}}
					</p>
				</div>
				<div class="row">
					<p class="label">
						SKU
					</p>
					<p class="value">
						{{ accountStore.accountDetails.sub?.productId ?? 'N/A' }}
					</p>
				</div>
				<div class="row">
					<p class="label">
						Subscription provider
					</p>
					<p class="value">
						{{ 
							accountStore.accountDetails.sub?.data?.paymentProcessor 
							? accountStore.accountDetails.sub?.data?.paymentProcessor : accountStore.accountDetails.sub?.paymentProcessor 
							? accountStore.accountDetails.sub?.paymentProcessor : 'N/A' 
						}}
					</p>
				</div>
				<div class="row">
					<p class="label">
						Account Email
					</p>
					<p class="value" v-if="emailEditState !== EditState.Editing">
						{{ accountStore.accountDetails.email }}
						<button class="email-button" @click="emailEditState = EditState.Editing">
							Change
						</button>
					</p>
					<div class="email-edit" v-if="emailEditState === EditState.Editing">
						<input class="email-input" type="text" v-model="accountEmail" >
						<LoadingSpinner :size="1" v-if="emailUpdateState === LoadingState.Loading"></LoadingSpinner>
						<button :disabled="emailUpdateState === LoadingState.Loading" class="email-button" @click="updateEmail()">Save</button> 
						<button class="email-button" @click="emailEditState = EditState.Idle" :disabled="emailUpdateState === LoadingState.Loading">Cancel</button>
					</div>
				</div>
			</div>
			<div class="info-container">
				<div class="align-center-row" v-if="getDevicesState === LoadingState.Loading">
					<LoadingSpinner :size="4" class="primary"></LoadingSpinner>
				</div>
				<div class="align-center-column" v-if="getDevicesState === LoadingState.Error">
					<p>Failed to load devices.</p>
					<div>
						<AppButton class="secondary" @click="loadDevices()">Try Again</AppButton>
					</div>
				</div>
				<TableView class="table" v-if="getDevicesState === LoadingState.Done">
					<template #header>
						<TableHeader class="no-padding-top">Device</TableHeader>
						<TableHeader class="small no-padding-top">Last login</TableHeader>
					</template>
					<template #item>
						<TableRow class="no-cursor">
							<TableData class="small">
								<div class="platform-chip">iOS</div>
							</TableData>
							<TableData class="small">
								{{ accountStore.devices.ios?.last_login ? FormatDate(accountStore.devices.ios?.last_login) : 'N/A' }}
							</TableData>
						</TableRow>
						<TableRow class="no-cursor">
							<TableData class="small">
								<div class="platform-chip">Android</div>
							</TableData>
							<TableData class="small">
								{{ accountStore.devices.android?.last_login ? FormatDate(accountStore.devices.android?.last_login) : 'N/A' }}
							</TableData>
						</TableRow>
						<TableRow class="no-cursor">
							<TableData class="small">
								<div class="platform-chip">Website</div>
							</TableData>
							<TableData class="small">
								{{ accountStore.devices.web?.last_login ? FormatDate(accountStore.devices.web?.last_login) : 'N/A' }}
							</TableData>
						</TableRow>
					</template>
				</TableView>
			</div>
			<div class="info-container lang">
				<h3>Language pairs</h3>
				<p>Not implemented</p>
			</div>

			<div class="info-container">
				<h3>Activity log</h3>
				<p>Not implemented</p>
			</div>
			<div class="info-container">
				<h3>Feedback log</h3>
				<p>Not implemented</p>
			</div>
		</div>
		<div v-else-if="accountLoadingState === LoadingState.Error"></div>
		<div v-else class="loading">
			<LoadingSpinner :size="4" class="primary"></LoadingSpinner>
		</div>
	</div>
</template>

<style scoped>
.page-container {
	width: 100vw !important;
	height: 100wh !important;
	padding: 2.5em 2em;
}

.page-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2em;
}

.page-header .back-button {
	display: flex;
	align-items: center;
	color: var(--color-dark);
	font-size: 1.4em;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh !important;
	width: 100%;
}

.grid {
	color: var(--color-dark);
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 1em;
	column-gap: 1em;
}

.grid .info-container {
	border: solid 1px #D2D6D9;
	border-radius: 8px;
	padding: 1em;
	overflow: scroll;
}

.info-container h3 {
	font-weight: 500;
	margin-bottom: 1em;
}

.info-container .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-top: solid 1px #C7C7C7;
}	

.info-container .row .label {
	color: #919699;
	font-size: 0.75em;
	text-transform: uppercase;
	padding: 0.75em 0;
	min-width: 140px;
	margin-right: 0.5em;
}

.info-container .row .value {
	overflow: scroll;
}

.lang {
	grid-column: 1 / span 2;
	background: #F2F2F7;;
}

.back-button p {
	padding-left: 1em;
}

.no-padding-top {
	padding-top: 0 !important;
}

.platform-chip {	
	background: #1A98FF14;
	border: solid 1px var(--color-background-shade);
	border-radius: 20px;
	min-height: 20px;
	width: min-content;
	padding: 3px 0.5em;
}

.platform-chip.small {	
	min-height: 20px;
	width: min-content;
	padding: 3px 0.5em;
	font-size: 0.8em
}

.no-cursor {
	cursor: default;
}

.email-button {
    font-size: 0.8em;
	font-weight: 600;
    color: var(--color-dark);
    background-color: var(--color-light-blue);
    border: none;
    border-radius: 100px;
    padding: 7px 15px;
    transition: opacity 50ms ease-in-out;
    cursor: pointer;
	margin-left: 0.4em;
}

.email-input {
	border-radius: 25px;
	padding: 0.4em 1em;
	min-width: 250px;
	background-color: #F2F2F7;
	border: none;
	color: var(--color-dark);
}
</style>