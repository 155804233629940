import router from '@/router';
import { useAuthStore } from '@/stores/auth';

export const onFulfilledInterceptor = (response) => {
	return response;
};

export const onRejectedInterceptor = (error) => {
	const auth = useAuthStore();

	if (error?.request?.status === 401) {
		auth.logOut();
		router.replace({ path: '/login' });
	}

	return Promise.reject(error);
};
