import { ref } from 'vue';
import { defineStore } from 'pinia';
import LogsService from '@/services/logs.service';

export const useLogsStore = defineStore('logs', () => {
	const service = new LogsService();
	const logs = ref({
		cursor: null,
		list: [],
	});

	const getLogs = async (pageSize = 30) => {
		let res = await service.GetLogs(pageSize, logs.value.cursor);

		logs.value.cursor = res.cursor;
		if (res.logs != null) {
			logs.value.list = [...logs.value.list, ...res.logs];
		}
	};

	return {
		getLogs,
		logs,
	};
});
