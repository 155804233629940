import { ref } from 'vue';
import { defineStore } from 'pinia';
import AdminService from '@/services/admin.service';

export const useAdminStore = defineStore('admin', () => {
	const service = new AdminService();
	const list = ref([]);

	const GetAdminUsers = async () => {
		list.value = await service.GetAdminUsers();
	};

	const UpdateUser = async (user) => {
		await service.UpdateUser(user);
	};

	const DeleteUser = async (id) => {
		await service.DeleteUser(id);
		list.value.splice(
			list.value.findIndex((item) => item.id === id),
			1
		);
	};

	const CreateUser = async (user) => {
		await service.CreateUser(user);
	};

	return {
		GetAdminUsers,
		UpdateUser,
		DeleteUser,
		CreateUser,
		list,
	};
});
