import { ref } from 'vue';
import { defineStore } from 'pinia';
import AccountService from '@/services/account.service';

export const useAccountStore = defineStore('account', () => {
	const service = new AccountService();
	const list = ref([]);
	const accountDetails = ref(null);
	const devices = ref({
		ios: null,
		android: null,
		web: null,
	});

	const getAccounts = async (filter) => {
		list.value = [];
		list.value = await service.GetAccounts(filter);
		if (list.value === null) {
			list.value = [];
			return;
		}
		list.value = list.value.map((item) => {
			item.sub = null;
			if (item.subscription && item.subscription?.subscriptions.length !== 0) {
				for (let sub of item.subscription.subscriptions) {
					if (sub.data.active) {
						item.sub = sub;
					}
				}
				if (!item.sub) {
					item.sub = item.subscription.subscriptions[0];
				}
			}

			return item;
		});
	};

	const verifyAccount = async (id) => {
		await service.VerifyAccount(id);
		accountDetails.value.activated = true;
	};

	const getAccountById = async (id) => {
		await getAccounts({ uuid: id });
		if (list.value.length === 0) {
			accountDetails.value = null;
			return;
		}
		setAccountDetails(list.value[0]);
	};

	const setAccountDetails = (account) => {
		accountDetails.value = account;
	};

	const getDevices = async (id) => {
		let devicesList = await service.GetDevices(id);
		if (devicesList == null) {
			return;
		}

		devices.value.web = devicesList.find((item) => {
			if (item.app.includes('web')) {
				return item;
			}
		});

		devices.value.android = devicesList.find((item) => {
			if (item.display_name.toLowerCase().includes('android')) {
				return item;
			}
		});

		devices.value.ios = devicesList.find((item) => {
			if (item.display_name.toLowerCase().includes('ios')) {
				return item;
			}
		});
	};

	const changePremiumStatus = async (id) => {
		await service.UpdatePremiumStatus(
			id,
			accountDetails.value.premium_subscription
		);
		accountDetails.value.premium_subscription =
			!accountDetails.value.premium_subscription;
	};

	const deleteAccount = async (id) => {
		await service.DeleteAccount(id, accountDetails.value.ecosystem);
	};

	const clearList = () => {
		list.value = [];
	};

	const updateEmail = async (id, email) => {
		await service.UpdateEmail(id, email);
		accountDetails.value.email = email;
	};

	return {
		getAccounts,
		setAccountDetails,
		getDevices,
		getAccountById,
		deleteAccount,
		changePremiumStatus,
		verifyAccount,
		clearList,
		accountDetails,
		updateEmail,
		list,
		devices,
	};
});
