import axios from 'axios';

export default class AdminService {
	async GetAdminUsers() {
		let res = await axios.get('/admin/users');
		return res.data;
	}

	async DeleteUser(id) {
		await axios.delete(`/admin/user/${id}/delete`);
	}

	async UpdateUser(user) {
		await axios.put(`/admin/user`, user);
	}

	async CreateUser(user) {
		await axios.post(`/admin/user`, user);
	}

	async ReloadRules() {
		await axios.post(`/admin/casbin/reload`);
	}
}
