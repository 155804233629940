<template>
    <td>
        <slot></slot>
    </td>
</template>

<style scoped>
td {
    color: var(--color-dark);
    border-collapse: collapse;
    padding: 5px 10px;
    min-height: 50px;
    padding: 0.75em 1em;
    font-size: 1.1em;
}

.small {
    font-size: 0.9em;
    min-height: 35px;
}

.bold {
    font-weight: 500;
}
</style>