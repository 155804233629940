<script setup>
import { onMounted, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import TableView from '@/components/table/TableView.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableData from '@/components/table/TableData.vue';
import TableRow from '@/components/table/TableRow.vue';
import { useAdminStore } from '@/stores/admin.store';
import { ShowError, FormatDate } from '@/services/util';
import { LoadingState } from '@/services/enums';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AppSelect from '@/components/select/AppSelect.vue';
import CreateAdminUserModal from './CreateAdminUserModal.vue';
import { useAuthStore } from '@/stores/auth';
import { AdminUserActionsPermission } from '@/services/permissions';

const permissionFilter = ref('all');
const store = useAdminStore();
const authStore = useAuthStore();
const loadState = ref(LoadingState.Idle);
const showCreateModal = ref(false);
const filteredList = ref([]);

onMounted(() => {
	loadUsers()
});

const loadUsers = async () => {
	try {
		loadState.value = LoadingState.Loading;
		await store.GetAdminUsers();
		filteredList.value = store.list;
		loadState.value = LoadingState.Done;
	} catch (e) {
		ShowError(e);
		loadState.value = LoadingState.Error;
	}
};

const updateUserRole = async (user) => {
	try {
		await store.UpdateUser(user);
	} catch (e) {
		ShowError(e);
	}
};

const deleteUser = async (user) => {
	if (!window.confirm(`Are you sure you want to delete ${user.email}?`)) return;
	try {
		await store.DeleteUser(user.id);
	} catch (e) {
		ShowError(e);
	}
}

const filterByPermission = (permission) => {
	permissionFilter.value = permission;
	if (permission === 'all') {
		filteredList.value = store.list;
	} else {
		filteredList.value = store.list.filter(user => user.role === permission);
	}
};

const reload = () => {
	loadUsers();
	showCreateModal.value = false;
};
</script>

<template>
	<div class="page-container">
		<CreateAdminUserModal v-if="showCreateModal" @close="showCreateModal = false" @reload="reload()"></CreateAdminUserModal>
		<div class="row">
			<div class="row">
				<h1>Permissions</h1>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'all'}" @click="filterByPermission('all')">All</AppButton>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'admin'}" @click="filterByPermission('admin')">Admin</AppButton>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'dev'}" @click="filterByPermission('dev')">Dev</AppButton>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'qa'}" @click="filterByPermission('qa')">QA</AppButton>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'support'}" @click="filterByPermission('support')">Support</AppButton>
				<AppButton class="small" :class="{'secondary': permissionFilter !== 'pm'}" @click="filterByPermission('pm')">PM</AppButton>
			</div>
			<AppButton class="small add-button" @click="showCreateModal = true" v-if="authStore.checkPermission(AdminUserActionsPermission)">Add</AppButton>
		</div>
		<TableView v-if="loadState === LoadingState.Done">
			<template #header>
				<TableHeader>Email</TableHeader>
				<TableHeader>Created</TableHeader>
				<TableHeader>Role</TableHeader>
				<TableHeader>Active</TableHeader>
				<TableHeader></TableHeader>
			</template>
			<template #item>
				<TableRow v-for="user in filteredList" :key="user.id">
					<TableData>{{ user.email }}</TableData>
					<TableData>{{ FormatDate(user.created) }}</TableData>
					<TableData>
						<AppSelect v-model="user.role" @update:modelValue="updateUserRole(user)" :disabled="!authStore.checkPermission(AdminUserActionsPermission)">
							<template #options>
								<option value="all">All</option>
								<option value="admin">Admin</option>
								<option value="dev">Dev</option>
								<option value="qa">QA</option>
								<option value="support">Support</option>
								<option value="pm">PM</option>
							</template>
						</AppSelect>
					</TableData>
					<TableData>{{ user.deleted ? 'No' : 'Yes' }}</TableData>
					<TableData>
						<AppButton 
							class="secondary small" 
							@click="deleteUser(user)"
							v-if="authStore.checkPermission(AdminUserActionsPermission)"
						>Delete</AppButton>
					</TableData>
				</TableRow>
			</template>
		</TableView>
		<div v-if="loadState === LoadingState.Loading" class="align-center-row">
			<LoadingSpinner :size="4" class="primary"></LoadingSpinner>
		</div>
		<div v-if="loadState === LoadingState.Error">
			<div class="error-container">
				<p>Something went wrong, please try again.</p>
				<AppButton class="button" @click="loadUsers()">Try again</AppButton>
			</div>
		</div>
	</div>
</template>

<style scoped>
.page-container {
	width: 100vw;
	height: 100wh;
}
.row {
	display: flex;
	width: 100%;
}

.add-button {
	justify-self: flex-end;
}

.align-center-row {
	height: 100vh;
}
.error-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color-dark);
}

.error-container p {
	font-size: 1.4em;
}

.error-container .button {
	margin: 1em;
}
</style>