<script setup>
import TableView from '@/components/table/TableView.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableData from '@/components/table/TableData.vue';
import TableRow from '@/components/table/TableRow.vue';
import { ShowError, FormatDate } from '@/services/util';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { LoadingState } from '@/services/enums';
import { onMounted, ref } from 'vue';
import { useLogsStore } from '@/stores/logs.store';
import AppButton from '@/components/AppButton.vue';

const loadState = ref(LoadingState.Idle);
const store = useLogsStore();

onMounted(() => {
	loadLogs();
});

const loadLogs = async () => {
	try {
		loadState.value = LoadingState.Loading;
		await store.getLogs();
		loadState.value = LoadingState.Done;
	} catch (e) {
		ShowError(e);
		loadState.value = LoadingState.Error;
	}
}
</script>

<template>
	<div class="page-container">
		<h1>Logs</h1>
		<div v-if="loadState === LoadingState.Done">
			<TableView>
				<template #header>	
					<TableHeader>Created</TableHeader>
					<TableHeader>Path</TableHeader>
					<TableHeader>Method</TableHeader>
					<TableHeader>Body</TableHeader>
					<TableHeader>Query Params</TableHeader>
					<TableHeader>Requestor Email</TableHeader>
				</template>
				<template #item>
					<TableRow v-for="log in store.logs.list" :key="log.id">
						<TableData> {{ FormatDate(log.created_at) }} </TableData>
						<TableData> {{ log.url }} </TableData>
						<TableData> {{ log.method }} </TableData>
						<TableData> {{ log.body }} </TableData>
						<TableData> {{ log.query_params }} </TableData>
						<TableData> {{ log.user }} </TableData>
					</TableRow>
				</template>
			</TableView>
			<AppButton v-if="store.logs.cursor != null" @click="loadLogs()">Load more logs</AppButton>
		</div>
		<div v-else-if="loadState === LoadingState.Error">
			<div class="error-container">
				<p>Something went wrong, please try again.</p>
				<AppButton class="button" @click="loadLogs()">Try again</AppButton>
			</div>
		</div>
		<div v-else class="align-center-row">
			<LoadingSpinner :size="4" class="primary"></LoadingSpinner>
		</div>
	</div>
</template>

<style scoped>
.page-container {
	width: 100vw;
	height: 100wh;
}

.align-center-row {
	height: 100vh;
}

.error-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color-dark);
}
</style>