<script setup>
import {defineEmits, ref} from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/select/AppSelect.vue';
import {AppBundleIDs, Ecosystem, LoadingState} from '@/services/enums.js';
import {ShowError} from '@/services/util';
import {useSKUStore} from '@/stores/sku.store';

const store = useSKUStore();
const emit = defineEmits(['close', 'reload']);
const sku = ref({
  ecosystem: 'itranslate',
  platform: 'ios',
  bundle_id: '',
  duration: 7,
  group: '',
  intro: null,
  intro_duration: null,
  license: 'individual',
  price: null,
  product_id: '',
  store: '',
  title: '',
  trial_duration: null,
  user: 'admin',
});

// const durationPeriod = {
// 	7: 'week',
// 	14: '2weeks',
// 	21: '3weeks',
// 	30: 'month',
// 	60: '2months',
// 	90: '3months',
// 	365: 'year',
// }

const createState = ref(LoadingState.Idle);

const updateBundleID = () => {
  if (sku.value.platform === 'web') {
    sku.value.bundle_id = '*';
    sku.value.store = 'stripe';
  } else if (sku.value.platform === 'ios') {
    sku.value.store = 'appstore';
    sku.value.bundle_id = sku.value.ecosystem === Ecosystem.iTranslate ? AppBundleIDs.iOSiTranslate : sku.value.ecosystem === Ecosystem.Grammatica ? AppBundleIDs.iOSGrammatica : AppBundleIDs.SpeakAndTranslateIOs;
  } else if (sku.value.platform === 'android') {
    sku.value.store = 'playstore';
    sku.value.bundle_id = sku.value.ecosystem === Ecosystem.iTranslate ? AppBundleIDs.AndroidiTranslate : AppBundleIDs.AndroidGrammatica;
  }
};

const create = async () => {
  try {
    createState.value = LoadingState.Loading;
    sku.value.duration = parseInt(sku.value.duration);
    await store.createSKU(sku.value);
    emit('reload');
  } catch (e) {
    ShowError(e)
  } finally {
    createState.value = LoadingState.Done;
  }
};

// const formatProductID = () => {
// 	let id = `${sku.value.ecosystem}.${sku.value.platform}.${durationPeriod[sku.value.duration]}`;
// 	if (sku.value.price) {
// 		let price = `${sku.value.price}`;
// 		id += `.${price.replaceAll('.', '_')}`;
// 	}
// 	if (sku.value.intro_duration) {
// 		let intro = sku.value.intro ? `_${sku.value.intro}` : '';
// 		id += `.intro_${sku.value.intro_duration}days${intro.replaceAll('.', '_')}`;
// 	}
// 	if (sku.value.trial_duration) {
// 		id += `.trial_${sku.value.trial_duration}days`;
// 	}
// 	if (sku.value.group) {
// 		id += `.group_${sku.value.group.replaceAll(' ', '_')}`;
// 	}
// 	sku.value.product_id = id;
// }
// formatProductID();

const isValid = () => {
  return sku.value.price && sku.value.group && sku.value.title && sku.value.product_id;
};
</script>

<template>
  <div class="modal-backdrop" @mousedown="emit('close')">
    <div class="modal" @click.stop @mousedown.stop>
      <div class="modal-header">
        <AppButton class="secondary small" @click="emit('close')">Close</AppButton>
        <h2>Create SKU</h2>
      </div>
      <div>
        <form action="submit" @submit.prevent="create">
          <div class="row">
            <label>
              <p>Ecosystem</p>
              <AppSelect v-model="sku.ecosystem" @update:modelValue="updateBundleID()">
                <template #options>
                  <option :value="Ecosystem.iTranslate">iTranslate</option>
                  <option :value="Ecosystem.Grammatica">Grammatica</option>
                  <option :value="Ecosystem.SpeakAndTranslate">Speak&Translate</option>
                </template>
              </AppSelect>
            </label>
            <label>
              <p>Platform</p>
              <AppSelect v-model="sku.platform" @update:modelValue="updateBundleID()">
                <template #options>
                  <option value="ios">iOS</option>
                  <option value="android">Android</option>
                  <option value="web">Web</option>
                </template>
              </AppSelect>
            </label>
          </div>
          <div class="row">
            <label>
              <p>Duration</p>
              <AppSelect v-model="sku.duration">
                <template #options>
                  <option value="7">Week</option>
                  <option value="14">2 Weeks</option>
                  <option value="21">3 Weeks</option>
                  <option value="30">Month</option>
                  <option value="60">2 Months</option>
                  <option value="90">3 Months</option>
                  <option value="365">Year</option>
                </template>
              </AppSelect>
            </label>
            <label>
              <p>License</p>
              <AppSelect v-model="sku.license">
                <template #options>
                  <option value="individual">Individual</option>
                  <option value="team">Team</option>
                </template>
              </AppSelect>
            </label>
          </div>
          <div class="row">
            <label>
              <p>Group</p>
              <div class="input-field">
                <input v-model="sku.group" required type="text" placeholder="Group"/>
              </div>
            </label>
            <label>
              <p>Title</p>
              <div class="input-field">
                <input v-model="sku.title" required type="text" placeholder="Title"/>
              </div>
            </label>
          </div>
          <div class="row">
            <label>
              <p>Trial duration (days)</p>
              <div class="input-field">
                <input v-model="sku.trial_duration" type="number" placeholder="Trial duration"/>
              </div>
            </label>
            <label>
              <p>Intro price ($4.99)</p>
              <div class="input-field">
                <input v-model="sku.intro" type="number" step="0.01" placeholder="Intro price"/>
              </div>
            </label>
            <label>
              <p>Intro duration (days)</p>
              <div class="input-field">
                <input v-model="sku.intro_duration" type="number" placeholder="Intro duration"/>
              </div>
            </label>
          </div>
          <label>
            <p>Price ($4.99)</p>
            <div class="input-field">
              <input v-model="sku.price" required type="number" step="0.01" placeholder="Price"/>
            </div>
          </label>
          <label>
            <p>Product ID</p>
            <div class="input-field">
              <input v-model="sku.product_id" type="text" placeholder="Product ID"/>
            </div>
          </label>
          <div class="align-center-row">
            <AppButton @click="create()" :disabled="!isValid() || createState === LoadingState.Loading"
                       :loading="createState === LoadingState.Loading" type="submit">Create SKU
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
form {
  margin: 2em;
  min-width: 800px;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

label {
  width: 100%;
  font-size: 1.1em;
}

label p {
  margin-left: 2em;
  margin-top: 0.5em;
}
</style>