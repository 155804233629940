import { createRouter, createWebHashHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import AppView from '@/views/app/AppView.vue';
import UsersView from '@/views/app/users/UsersView.vue';
import SkusView from '@/views/app/skus/SkusView.vue';
import QAAccountsView from '@/views/app/qa/QAAccountsView.vue';
import PermissionsView from '@/views/app/permissions/PermissionsView.vue';
import NotFound from '@/views/NotFound.vue';
import LoginView from '@/views/LoginView.vue';
import UserSearch from '@/views/app/users/UserSearch.vue';
import UserDetails from '@/views/app/users/UserDetails.vue';
import LogsView from '@/views/app/logs/LogsView.vue';

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: LoginView,
		},
		{
			path: '/',
			name: 'app-view',
			redirect: '/users',
			component: AppView,
			children: [
				{
					path: '/users',
					name: 'Users',
					component: UsersView,
					children: [
						{
							path: '',
							name: 'UserSearch',
							component: UserSearch,
						},
						{
							path: ':id',
							name: 'UserDetails',
							component: UserDetails,
						},
					],
				},
				{
					path: '/skus',
					name: 'Skus',
					component: SkusView,
				},
				{
					path: '/qa-accounts',
					name: 'QAAccounts',
					component: QAAccountsView,
				},
				{
					path: '/permissions',
					name: 'Permissions',
					component: PermissionsView,
				},
				{
					path: '/logs',
					name: 'Logs',
					component: LogsView,
				},
			],
		},
		{
			path: '/:pathMatch(.*)',
			name: 'not-found',
			component: NotFound,
		},
	],
});

router.beforeEach(async (to) => {
	const auth = useAuthStore();

	if (!auth.isAuthenticated() && to.name !== 'Login') {
		return { name: 'Login', query: { redirect: to.path } };
	}
});

export default router;
