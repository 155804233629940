<!-- eslint-disable no-undef -->
<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';

const auth = useAuthStore();
const route = useRouter();

onMounted(() => {
    google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: handleToken,
    });
    google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
        theme: 'outline',
        size: 'large',
    });
    google.accounts.id.prompt();
});

const handleToken = async (values) => {
    try {
      await auth.logIn(values.credential)

      let routeTo = '/users';
      console.log(route.currentRoute.value.query)
      if (route.currentRoute.value.query?.redirect && route.currentRoute.value.query?.redirect !== '') {
          routeTo = route.currentRoute.value.query.redirect;
      }
      route.replace(routeTo);
    } catch (e) {
      window.alert(e.message)
    }
};
</script>

<template>
    <main>
		<div id="buttonDiv"></div>
    </main>
</template>

<style scoped>
#buttonDiv{
    margin: 2rem;
}
</style>