<script setup>
import { defineEmits, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/select/AppSelect.vue';
import { ShowError } from '@/services/util';
import { LoadingState } from '@/services/enums';
import { useAdminStore } from '@/stores/admin.store';

const store = useAdminStore();
const emit = defineEmits(['close', 'reload']);
const createState = ref(LoadingState.Idle);
const user = ref({
	email: '',
	role: 'dev',
});

const create = async () => {
	try {
		createState.value = LoadingState.Loading;
		await store.CreateUser(user.value);
		emit('reload');
	} catch (e) {
		ShowError(e);
	} finally {
		createState.value = LoadingState.Idle;
	}
}

const isValid = () => {
	return user.value.email && user.value.role;
}
</script>

<template>
    <div class="modal-backdrop" @mousedown="emit('close')">
        <div class="modal" @click.stop @mousedown.stop>
            <div class="modal-header">
                <AppButton class="secondary small" @click="emit('close')">Close</AppButton>
                <h2>Add User</h2>
            </div>
			<div>
				<form action="submit" @submit.prevent="create">
					<label>
						<p>Email</p>
						<div class="input-field">
							<input v-model="user.email" required type="text" placeholder="Email"/>
						</div>
					</label>
					<label>
						<p>Role</p>
						<AppSelect v-model="user.role">
							<template #options>
								<option value="all">All</option>
								<option value="admin">Admin</option>
								<option value="dev">Dev</option>
								<option value="qa">QA</option>
								<option value="support">Support</option>
								<option value="pm">PM</option>
							</template>
						</AppSelect>
					</label>
					<div class="align-center-row">
						<AppButton @click="create()" :loading="createState === LoadingState.Loading" :disabled="createState === LoadingState.Loading || !isValid()" type="submit">Add User</AppButton>
					</div>
				</form>
			</div>
        </div>
    </div>
</template>

<style scoped>
form {
	margin: 2em;
	min-width: 400px;
}

.row {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

label {
	width: 100%;
	font-size: 1.1em;
}

label p {
	margin-left: 2em;
	margin-top: 0.5em;
}

.align-center-row {
	margin-top: 2em;
}
</style>