import moment from 'moment';

export function ShowError(e) {
	let m = 'Error';
	if (e?.response?.data?.message) {
		m = e?.response?.data?.message;
	} else {
		m = e?.toString();
	}
	window.alert(m);
}

export function FormatDate(date, time = true) {
	return date !== null
		? moment(date).format(time ? 'YYYY/MM/DD, h:mm:ss a' : 'YYYY-MM-DD')
		: '';
}
