<script setup>
import { useSKUStore } from '@/stores/sku.store';
import { LoadingState } from '@/services/enums.js';
import TableView from '@/components/table/TableView.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableData from '@/components/table/TableData.vue';
import TableRow from '@/components/table/TableRow.vue';
import { onMounted, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/select/AppSelect.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { ShowError } from '@/services/util';
import SkuCreateModel from '@/views/app/skus/SkuCreateModel.vue';
import SkuNoteModal from './SkuNoteModal.vue';
import { CreateSKUPermission } from '@/services/permissions';
import { useAuthStore } from '@/stores/auth';

const store = useSKUStore();
const authStore = useAuthStore();
const skuState = ref(LoadingState.Idle);
const filter = ref({
	app: '',
	store: '',
});
const filteredList = ref([]);
const showCreateModal = ref(false);
const noteUpdateSKU = ref(null)

onMounted(() => {
	loadSKUs();
});

const loadSKUs = async () => {
	try {
		skuState.value = LoadingState.Loading;
		await store.getSKUs();
		filteredList.value = store.list;
		skuState.value = LoadingState.Done;
	} catch (error) {
		ShowError(error);
		skuState.value = LoadingState.Error;
	}
};

const applyFilter = () => {
	filteredList.value = store.list.filter((sku) => {
		const appMatch = filter.value.app === '' || filter.value.app === sku.app;
		const storeMatch = filter.value.store === '' || filter.value.store === sku.store;
		return appMatch && storeMatch;
	});
};

const clearFilters = () => { 
	filter.value.app = '';
	filter.value.store = '';
	filteredList.value = store.list;
}

const openCloseModal = () => {
	showCreateModal.value = !showCreateModal.value;
};

const reload = () => {
	loadSKUs();
	openCloseModal();
};
</script>

<template>
	<div class="page-container">
		<SkuCreateModel v-if="showCreateModal" @close="openCloseModal()" @reload="reload()"></SkuCreateModel>
		<SkuNoteModal v-if="noteUpdateSKU != null" :sku="noteUpdateSKU" @close="noteUpdateSKU = null"></SkuNoteModal>
		<div class="header-container">
			<div class="filters">
				<h3>Filters</h3>
				<AppSelect v-model="filter.app" @update:modelValue="applyFilter()">
					<template #options>
						<option value="">App</option>
						<option value="itranslate">iTranslate</option>
						<option value="grammatica">Grammatica</option>
						<option value="speak&translate">Speak&Translate</option>
						<option value="unknown">Other</option>
					</template>
				</AppSelect>
				<AppSelect v-model="filter.store" @update:modelValue="applyFilter()">
					<template #options>
						<option value="">Store</option>
						<option value="appstore">AppStore</option>
						<option value="playstore">PlayStore</option>
						<option value="stripe">Stripe</option>
						<option value="unknown">Other</option>
					</template>
				</AppSelect>
				<AppButton 
					class="secondary small" 
					@click="clearFilters" 
					v-if="filter.app !== '' || filter.store !== ''"
				>
					Clear filters
				</AppButton>
			</div>
			<AppButton @click="openCloseModal()" v-if="authStore.checkPermission(CreateSKUPermission)">Add</AppButton>
		</div>
		<div v-if="skuState === LoadingState.Done">
			<TableView>
				<template #header>
					<TableHeader>Product ID</TableHeader>
					<TableHeader>App</TableHeader>
					<TableHeader>Title</TableHeader>
					<TableHeader>Product Group</TableHeader>
					<TableHeader>Price</TableHeader>
					<TableHeader>ID</TableHeader>
					<TableHeader>Note</TableHeader>
				</template>
				<template #item>
					<TableRow v-for="sku in filteredList" :key="sku.id" @click="noteUpdateSKU = sku">
						<TableData class="column-width">{{ sku.product_id }}</TableData>
						<TableData class="column-width">
							<div class="app-box" :class="sku.app">
								{{
                  sku.app === 'itranslate' ? 'iT' : sku.app === 'grammatica' ? 'G' : sku.app === 'speak&translate' ? 'S&T' : 'N/A'
                }}
							</div>
						</TableData>
						<TableData class="column-width">{{ sku.title }}</TableData>
						<TableData class="column-width">{{ sku.group }}</TableData>
						<TableData class="column-width">{{ sku.price }}</TableData>
						<TableData class="column-width">{{ sku.id }}</TableData>
						<TableData class="column-width"><textarea class="note" :disabled="true" :value="sku.note"></textarea></TableData>
					</TableRow>
				</template>
			</TableView>
		</div>
		<div v-if="skuState === LoadingState.Loading" class="align-center-row">
			<LoadingSpinner :size="4" class="primary"></LoadingSpinner>
		</div>
		<div v-if="skuState === LoadingState.Error">
			<div class="error-container">
				<p>Something went wrong, please try again.</p>
				<AppButton class="button" @click="loadSKUs()">Try again</AppButton>
			</div>
		</div>
	</div>
</template>

<style scoped>
.page-container {
	width: 100vw;
	height: 100wh;
}

.header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 1em;
}

.header-container h3 {
	color: var(--color-dark);
	font-weight: 600;
}

.filters {
	display: flex;
	align-items: center;
}

.column-width {
	max-width: 350px;
	overflow: scroll;
}

.app-box {
	border-radius: 4px;
	color: white;
	text-align: center;
	padding: 0.2em 0.4em;
}

.app-box.itranslate {
	background: #0099FF;
}
.app-box.grammatica {
	background: #29CC6A;
}

.app-box.unknown {
	background: #e30e0ede;
}
.error-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color-dark);
}

.error-container p {
	font-size: 1.4em;
}

.error-container .button {
	margin: 1em;
}

.align-center-row {
	height: 100vh;
}

.note {
	border: none;
	background: #ffffff00;
}
</style>