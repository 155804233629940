import axios from 'axios';

export default class AccountService {
	async GetAccounts(filter) {
		let res = await axios.get('/admin/account', { params: filter });
		return res.data;
	}

	async VerifyAccount(id) {
		await axios.put(`/admin/account/${id}/activate`);
	}

	async UpdatePremiumStatus(id, premium) {
		await axios.put(`/admin/account/${id}/set-premium?set_premium=${!premium}`);
	}

	async GetDevices(id) {
		return (await axios.get(`/admin/account/${id}/devices`)).data;
	}

	async DeleteAccount(id) {
		await axios.delete(`/admin/account/${id}`);
  }

	async UpdateEmail(id, email) {
		await axios.put(
			`/admin/account/${id}/update-email`,
			{},
			{ params: { email } }
		);
	}
}
