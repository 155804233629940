import axios from 'axios';

export default class LogsService {
	async GetLogs(page_size = 30, cursor = null) {
		let res = await axios.get(`/admin/logs`, {
			params: { page_size, cursor },
		});
		return res.data;
	}
}
