<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth';
import { LogsPermission } from '@/services/permissions';

const isStaging = ref(process.env.VUE_APP_IS_STAGING === 'true')
const authStore = useAuthStore();
</script>

<template>
    <main>
        <div class="container">
            <div class="logo">
                <RouterLink to="/">
                    <img src="@/assets/logo.svg" alt="logo" />
                </RouterLink>
            </div>
            <div class="routes">
                <RouterLink
                    class="route"
                    to="/users"
                    active-class="is-active"
                >
                    <div class="item-bar"></div>
                    <p>User Management</p>
                </RouterLink>
                <RouterLink
                    class="route"
                    to="/skus"
                    active-class="is-active"
                >
                    <div class="item-bar"></div>
                    <p>SKU's</p>
                </RouterLink>
                <RouterLink
                    class="route"
                    to="/qa-accounts"
                    active-class="is-active"
                >
                    <div class="item-bar"></div>
                    <p>QA Accounts</p>
                </RouterLink>
                <RouterLink
                    class="route"
                    to="/permissions"
                    active-class="is-active"
                >
                    <div class="item-bar"></div>
                    <p>Permissions</p>
                </RouterLink>
                <RouterLink
                    class="route"
                    to="/logs"
                    active-class="is-active"
                    v-if="authStore.checkPermission(LogsPermission)"
                >
                    <div class="item-bar"></div>
                    <p>Activity Logs</p>
                </RouterLink>
            </div>
            <div v-if="isStaging" class="staging">
                <p>iTranslate Staging Admin</p>
            </div>
        </div>
    </main>
</template>

<style scoped>
.container {
    width: 275px;
    height: 100vh;
    background-color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: width 0.3s ease-in-out;
    position: fixed;
}

.logo {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.routes {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.route {
    color: var(--color-white);
    text-decoration: none;
    font-weight: normal;
    text-align: left;
    padding: 10px 12px;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    margin: 5px 0;
}

.route p {
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 1.1em;
}

.route:hover {
    background-color: var(--color-white-transparent) !important;
}

.route.is-active {
    background-color: var(--color-white-transparent) !important;
}

.route.is-active .item-bar {
    background-color: var(--color-blue) !important;
    border-color: var(--color-blue) !important;
}

.route.is-active p {
    font-weight: 500;
}

.route img {
    margin-right: 10px;
    width: 28px;
    height: 28px;
}

.item-bar {
    height: 90%;
    background: #ffffff00;
    border-color: #ffffff00;
    border-radius: 5px;
    width: 5px;
    align-self: flex-start !important;
    margin-right: 1em;
}

.staging {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: red;
}

.staging p {
    font-weight: 600;
    font-size: 1.1em;
    text-align: center;
}
</style>